import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountryCard from "../../components/CountryCard";
import LeftArrowIcon from "../../components/LeftArrowIcon";
import RightArrowIcon from "../../components/RightArrowIcon";
import Visa from "../../assets/images/homepage/visa.png";

export default function About() {
  function SampleNextArrow(props) {
    const { onClick } = props;
    const [rightPosition, setRightPosition] = useState("-40px");

    useEffect(() => {
      function handleResize() {
        if (window.innerWidth < 600) {
          // Adjust the 600px breakpoint as needed
          setRightPosition("-10px");
        } else {
          setRightPosition("-40px");
        }
      }

      // Set the position on initial render
      handleResize();

      // Add event listener for window resize
      window.addEventListener("resize", handleResize);

      // Clean up the event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <RightArrowIcon
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          right: rightPosition,
          transform: "translateY(-120%)",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    const [leftPosition, setLeftPosition] = useState("-40px");

    useEffect(() => {
      function handleResize() {
        if (window.innerWidth < 600) {
          // You can adjust the 600px breakpoint as needed
          setLeftPosition("-10px");
        } else {
          setLeftPosition("-40px");
        }
      }

      // Set the position on initial render
      handleResize();

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Clean up the event listener
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <LeftArrowIcon
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          left: leftPosition,
          transform: "translateY(-120%)",
        }}
        onClick={onClick}
      />
    );
  }
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const countries = [
    {
      bgImage: "bg-egypt",
      country: "Egypt",
      state: "Cairo, Egypt",
    },
    {
      bgImage: "bg-india",
      country: "India",
      state: "Madhya Pradesh, India",
    },
    { bgImage: "bg-kenya", country: "Kenya", state: "Maasai Mara, Kenya" },
  ];
  return (
    <div className="p-16 sm:p-6">
      <div className="grid grid-cols-3 sm:grid-cols-1 gap-10 sm:gap-y-10 sm:gap-x-0 items-center">
        <div>
          <h1 className="text-3xl font-bold my-4">
            Dream Big, Travel Far: Your Odyssey Begins Now.
          </h1>
          <p>
            As you start your once-in-a-lifetime adventure, let us be your
            guiding light. Dare to dream big, and aim for distant horizons
            because your extraordinary journey begins right here, right now
          </p>
        </div>
        <div className="col-span-2">
          <Slider {...sliderSettings}>
            {countries.map((card, i) => (
              <div key={i} className="p-4 sm:p-2">
                <CountryCard
                  bgImage={card.bgImage}
                  country={card.country}
                  state={card.state}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="grid grid-cols-5 sm:grid-cols-1 gap-10 items-center">
        <div className="col-span-2">
          <img src={Visa} alt="" />
        </div>
        <div className="col-span-3">
          <h2 className="text-fem-blue text-xl font-bold">Our Experience</h2>
          <h1 className="font-bold text-4xl my-4">
            With Our Seasoned Expertise, We're Here to Serve You
          </h1>
          <p>
            Benefit from our wealth of industry experience as we stand ready to
            assist you. With a deep understanding of visa applications, travel
            arrangements, and the intricacies of settling in diverse countries,
            we offer tailored guidance and solutions. Your unique journey is our
            priority, and we're dedicated to ensuring its seamless success.
            Entrust us with your aspirations, and we'll navigate the
            complexities, providing personalized support. Your desired
            destination is within reach, and we're here to make your dreams a
            reality, step by step.
          </p>
          <div className="flex gap-6 my-4">
            <div className="grid">
              <h1 className="text-3xl font-bold text-fem-blue my-4">10</h1>
              <p>Years</p>
              <p>Experience</p>
            </div>
            <div className="grid">
              <h1 className="text-3xl font-bold text-fem-blue my-4">600+</h1>
              <p>Destinations</p>
              <p>Collaborations</p>
            </div>
            <div className="grid">
              <h1 className="text-3xl font-bold text-fem-blue my-4">2K+</h1>
              <p>Happy</p>
              <p>Clients</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
