import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";

export default function Footer() {
  const currentYear = new Date().getFullYear(); // Get the current year
  return (
    <div className="grid mt-20 sm:mt-16">
      <div className="bg-fem-blue py-10 px-20 sm:p-6">
        <h1 className="text-2xl text-white sm:text-xl">
          Start your visa application journey.
        </h1>
      </div>
      <div className="grid grid-cols-6 gap-10 sm:gap-5 sm:grid-cols-1 py-10 px-20 sm:p-6">
        <div className="grid col-span-2 sm:col-span-1">
          <h1 className="py-8 sm:py-4 font-bold">Femfly</h1>
          <p>
            I'm looking forward to doing business with you. You can reach out
            using the details below.
          </p>
          <h1 className="py-8 sm:py-4 font-bold">Connect</h1>
          <div className="flex gap-4">
            <div className="rounded-full h-10 w-10 bg-fem-darkblue flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
              <NavLink to="#" target="_blank">
                <FaFacebookF color="white" />
              </NavLink>
            </div>
            <div className="rounded-full h-10 w-10 bg-fem-darkblue flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
              <NavLink to="#" target="_blank">
                <FaTwitter color="white" />
              </NavLink>
            </div>
            <div className="rounded-full h-10 w-10 bg-fem-darkblue flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
              <NavLink to="#" target="_blank">
                <FaInstagram color="white" />
              </NavLink>
            </div>
            <div className="rounded-full h-10 w-10 bg-fem-darkblue flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
              <NavLink to="#" target="_blank">
                <FaLinkedinIn color="white" />
              </NavLink>
            </div>
            <div className="rounded-full h-10 w-10 bg-fem-darkblue flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
              <NavLink to="#" target="_blank">
                <FaTiktok color="white" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="grid sm:col-span-1 sm:gap-2">
          <h1 className="pt-8 sm:py-4 font-bold">Links</h1>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/ourServices"> Our Services</NavLink>
          <NavLink to="/contactus">Contact</NavLink>
          <NavLink to="/aboutus">About us</NavLink>
        </div>
        <div className="grid sm:col-span-1 sm:gap-2">
          <h1 className="pt-8 sm:py-4 font-bold">Visas</h1>
          <NavLink to="/visa/student">Student Visa</NavLink>
          <NavLink to="/visa/business"> Business Visas</NavLink>
          <NavLink to="/visa/tourist">Tourist Visa</NavLink>
        </div>
        <div className="grid col-span-2 sm:col-span-1 sm:gap-2">
          <h1 className="pt-8 sm:py-4 font-bold">Contacts</h1>
          <p>+ 254 720 849 505</p>
          <p>+ 254 114 078 887</p>
          <a href={`mailto:femfly130@gmail.com`} className="hover:font-bold hover:text-fem-blue hover:text-base ease-in-out duration-75">femfly130@gmail.com</a>
          <p>Harambe Avenue, Cargen House, 3rd Floor, Room 305</p>
        </div>
      </div>
      {/* Updated section with dynamic year */}
      <div className="text-center my-8 text-sm">
        <p>
          Copyright ©{currentYear}. All Rights Reserved. — Designed & Built by 
          <NavLink to="https://www.stephenmuchara.com/" target="_blank" className="hover:font-bold mx-1">Stephen Muchara</NavLink>
        </p>
        <p>
          <a href="/terms-and-conditions" className="hover:underline">
            Terms & Conditions
          </a>{" "}
          |
          <a href="/privacy-policy" className="hover:underline">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}
