import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCard from "../../components/TestimonialCard";
import testimonialsData from "../../data/Testimonials.json";

export default function Testimonials() {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 3000, // Time in ms between each auto transition (e.g., 3000 ms = 3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="sm:-mt-8 sm:px-0">
      <Slider {...sliderSettings} className="my-6 sm:my-12">
        {testimonialsData.Testimonials.map((testimonial, index) => (
          <div key={index} className="p-4 sm:p-2">
            <TestimonialCard
              message={testimonial.message}
              author={testimonial.author}
              occupation={testimonial.occupation}
              authorInitial={testimonial.author.charAt(0)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
