import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import About from "../../assets/images/about/about.webp"

export default function HeroSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const stagger = {
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
    hidden: { y: 50, opacity: 0 },
  };

  return (
    <div className="relative p-16 mt-16 sm:mt-20 sm:p-6">
      <div className="grid grid-cols-5 sm:grid-cols-1 gap-10 sm:gap-0">
        <img src={About} alt="" className="col-span-2 h-[75vh] sm:h-[50vh] object-cover object-center"/>
        <div className="col-span-3 sm:col-span-1">
          <h1 className="text-8xl sm:text-6xl font-bold my-4 -ml-28 sm:ml-0">Our Vision</h1>
          <p className="mt-16 sm:mt-6">
            At Femfly Travel Agency, we envision a world without borders for
            travelers and dreamers. Our goal is to become a leading facilitator
            in global mobility, empowering individuals and families to explore,
            settle, and thrive in their destinations of choice. Central to our
            vision is the belief that travel and cultural exchange enrich lives
            and foster global understanding. We aspire to simplify the
            complexities of international travel, making it accessible and
            enjoyable for all, regardless of their destination. Through our
            services, we aim to open doors to new opportunities, adventures, and
            experiences, helping our clients to realize their dreams of
            exploring the world
          </p>
          <div ref={ref} className="flex gap-4 my-8">
            {[
              "facebook-circle",
              "instagram-alt",
              "tiktok",
              "linkedin-square",
            ].map((icon, index) => (
              <motion.div
                className="bg-white shadow-md rounded-md p-2 flex items-center justify-center"
                key={icon}
                custom={index}
                initial="hidden"
                animate={controls}
                variants={stagger}
              >
                <box-icon type="logo" name={icon}></box-icon>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
