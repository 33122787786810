import React from "react";

export default function BlogPreview({
  title,
  author,
  postedDate,
  thumbnail,
  alt,
}) {
  const backgroundImageStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + thumbnail})`,
    backgroundSize: "cover", // Ensure the image covers the whole div
    backgroundPosition: "center", // Center the image
    backgroundRepeat: "no-repeat", // Prevent image repetition
  };

  return (
    <div className="grid border border-gray-600 p-3 rounded-3xl h-[80vh] sm:h-[60vh]">
      <div style={backgroundImageStyle} className="h-[50vh] sm:h-[30vh] w-full rounded-3xl"></div>
      <div className="bg-white bg-opacity-60 rounded-3xl my-4">
        <p className="text-xl font-bold w-4/5">{title}</p>
        <p className="my-2 sm:text-xs">
          By {author} Posted on {postedDate}
        </p>
        <button className="py-2 px-4 sm:px-2 sm:py-3 w-1/2 border border-fem-blue text-black my-3 flex gap-2 items-center">
          <p>Learn More</p>
          <box-icon name="right-arrow-alt"></box-icon>
        </button>
      </div>
    </div>
  );
}
