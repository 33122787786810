import React from "react";

export default function OurServiceCard({ thumbnail, title, description }) {
  return (
    <div className="relative">
      <div
        className={`rounded-3xl ${thumbnail} h-[50vh] bg-cover bg-no-repeat bg-center`}
      ></div>
      <div className="rounded-3xl h-[50vh] bg-black bg-opacity-70 absolute top-0 left-0 right-0 bottom-0 z-10"></div>
      <div className="absolute bottom-0 left-0 z-30 p-4 grid place-items-end justify-items-start">
          <h2 className="text-white font-bold text-left my-6">{title}</h2>
          <p className="text-white text-sm">{description}</p>
      </div>
    </div>
  );
}
