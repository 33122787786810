import React, { useState } from "react";
import { motion, AnimatePresence} from "framer-motion";
import { NavLink } from "react-router-dom";

const DropdownLink = ({ title, subLinks }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Enhanced rotation transition styles
  const chevronStyles = {
    rotate: isOpen ? 90 : 0, // Rotate to 90 degrees when open
    transition: {
      duration: 0.3, // Smooth transition duration
      ease: "easeInOut" // Easing function for a more natural animation
    },
  };

  return (
    <motion.div onClick={handleClick} className="py-3 w-64 cursor-pointer">
      <div className="w-full flex items-center justify-between">
        {title}
        <motion.span style={chevronStyles}>
          <box-icon name="chevron-right"></box-icon>
        </motion.span>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="bg-white w-64 my-2"
          >
            {subLinks.map((subLink, idx) => (
              <NavLink
                to={`/${title.toLowerCase().replace(/\s+/g, "")}/${subLink
                  .toLowerCase()
                  .replace(/\s+/g, "")}`}
                key={idx}
              >
                <p className="py-2 hover:bg-gray-100">{subLink}</p>
              </NavLink>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default DropdownLink;
