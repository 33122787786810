import React from "react";

export default function CountryCard({ country, state, bgImage }) {
  return (
    <div className={`relative`}>
      <div
        className={`rounded-3xl ${bgImage} h-[45vh] bg-cover bg-no-repeat bg-center relative`}
      >
        <div className="absolute top-0 bottom-0 right-0 left-0 rounded-3xl bg-black opacity-65 z-20"></div>
      </div>
      <div className=" text-black my-3">
        <h1 className="text-lg font-bold">{state}</h1>{" "}
        <div className="flex gap-2 my-1">
          <p>{country}</p>
        </div>
      </div>
    </div>
  );
}
