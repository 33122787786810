import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import LeftArrowIcon from "../../components/LeftArrowIcon";
import RightArrowIcon from "../../components/RightArrowIcon";
import Slider from "react-slick";
import OurServiceCard from "../../components/OurServiceCard";

export default function HeroSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const stagger = {
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
    hidden: { y: 50, opacity: 0 },
  };
  function SampleNextArrow(props) {
    const { onClick } = props;
    const [rightPosition, setRightPosition] = useState("-40px");

    useEffect(() => {
      function handleResize() {
        if (window.innerWidth < 600) {
          // Adjust the 600px breakpoint as needed
          setRightPosition("-10px");
        } else {
          setRightPosition("-40px");
        }
      }

      // Set the position on initial render
      handleResize();

      // Add event listener for window resize
      window.addEventListener("resize", handleResize);

      // Clean up the event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <RightArrowIcon
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          right: rightPosition,
          transform: "translateY(-120%)",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    const [leftPosition, setLeftPosition] = useState("-40px");

    useEffect(() => {
      function handleResize() {
        if (window.innerWidth < 600) {
          // You can adjust the 600px breakpoint as needed
          setLeftPosition("-10px");
        } else {
          setLeftPosition("-40px");
        }
      }

      // Set the position on initial render
      handleResize();

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Clean up the event listener
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <LeftArrowIcon
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          left: leftPosition,
          transform: "translateY(-120%)",
        }}
        onClick={onClick}
      />
    );
  }
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ourServices = [
    {
        thumbnail: "bg-visa",
        title: "Visa Gateway",
        description:
            "Embark on your international travels with our specialized visa assistance. We streamline the visa process, ensuring compliance with global standards for a hassle-free experience.",
    },
    {
        thumbnail: "bg-flight",
        title: "Sky Journeys",
        description:
            "Stay updated with the latest flight information. We provide timely updates on schedules and changes, ensuring you're always prepared for your journey.",
    },
    {
        thumbnail: "bg-hotel",
        title: "Restful Retreats",
        description: "Discover the perfect stay with our wide range of hotel booking services. From luxury to budget-friendly, we cater to all your accommodation needs.",
    },
    {
        thumbnail: "bg-job",
        title: "Professional Pathways",
        description: "Bridge your career aspirations with opportunities worldwide. Our services simplify the job search process, aligning your skills with global demands.",
    },
    {
        thumbnail: "bg-hosting",
        title: "Cultural Connections",
        description: "Ease into your new destination with our hosting services. We connect you with welcoming local hosts for a smooth transition into your new environment.",
    }
];


  return (
    <div className="relative p-16 mt-8 sm:p-6">
      <div>
        <h1 className="text-6xl font-bold">Our Services</h1>
        <p className="w-2/5 sm:w-full my-6">
          Welcome to Femfly Travel Agency, your gateway to global adventures! At
          Femfly, we empower your travel dreams with expert guidance and
          comprehensive services. Explore the world with us!
        </p>
        <button className="px-4 py-4 my-6 sm:my-0 bg-fem-blue text-white rounded-lg">
          Book an appointment
        </button>
      </div>
      <div className="-mt-36 sm:my-0">
        <div className="grid w-full items-center justify-end sm:justify-start">
          <div ref={ref} className="flex gap-4 my-4">
            {[
              "facebook-circle",
              "instagram-alt",
              "tiktok",
              "linkedin-square",
            ].map((icon, index) => (
              <motion.div
                className="bg-white shadow-md rounded-md p-2 flex items-center justify-center"
                key={icon}
                custom={index}
                initial="hidden"
                animate={controls}
                variants={stagger}
              >
                <box-icon type="logo" name={icon}></box-icon>
              </motion.div>
            ))}
          </div>
        </div>
        <div className="w-full flex justify-end sm:justify-center">
          <div className="w-3/4 sm:w-full">
            <Slider {...sliderSettings}>
              {ourServices.map((card, i) => (
                <div key={i} className="p-4 sm:p-2">
                  <OurServiceCard
                    thumbnail={card.thumbnail}
                    title={card.title}
                    description={card.description}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
