import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { HiPaperAirplane } from "react-icons/hi";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    console.log(window.pageYOffset, window.innerHeight); // Add this to debug
    if (window.pageYOffset > window.innerHeight) {
      console.log("Setting visible"); // Debug log
      setIsVisible(true);
    } else {
      console.log("Setting invisible"); // Debug log
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const variants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };

  return isVisible ? (
    <motion.button
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={variants}
      onClick={scrollToTop}
      className="w-14 h-14 rounded-full bg-fem-darkblue text-white m-3 shadow-lg shadow-gray-500 flex justify-center items-center z-50"
    >
      <HiPaperAirplane size={28} />
    </motion.button>
  ) : null;
};

export default ScrollToTop;
