import React from "react";

export default function ProcessCard({ thumbnail, title, description }) {
  return (
    <div>
      <div className="grid">
        <div
          className={`bg-cover bg-center bg-no-repeat ${thumbnail}  relative`}
        >
          <div className="bg-black opacity-50 h-[45vh] sm:h-[40vh] w-full"></div>
          <div className="absolute top-0 left-0 right-0 z-10 m-8 text-white">
            <h1 className="font-bold text-2xl my-4 w-1/4 sm:w-full">{title}</h1>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
