import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const SideMenuLink = ({ title }) => {
  // Determine the navigation path: if the title is "Home", use "/", otherwise format the title.
  const navigatePath = title === "Home" ? "/" : `/${title.toLowerCase().replace(/\s+/g, "")}`;

  return (
    <motion.div className="py-3">
      <NavLink to={navigatePath}>
        {title}
      </NavLink>
    </motion.div>
  );
};

export default SideMenuLink;
