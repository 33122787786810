import React, { useEffect } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import DropdownLink from "./DropdownLink";
import SideMenuLink from "./SideMenuLink";

const SideMenu = ({ isOpen, onClose }) => {
  const menuItems = [
    { title: "Home" },
    { title: "Our Services" },
    {
      title: "Visa",
      subLinks: ["Business", "Tourist", "Student"],
    },
    { title: "Countries" },
    { title: "About Us" },
    { title: "Contact Us" },
  ];
  const controls = useAnimation();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    controls.start(isOpen ? "open" : "closed");
  }, [isOpen, controls]);

  const variants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        duration: 0.5,
        damping: 200,
        stiffness: 2000,
        delay: 0,
        when: "beforeChildren",
        staggerChildren: 0.2, // Reduced stagger duration
      },
    },
    closed: {
      opacity: 0,
      x: "-100%",
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial="closed"
          animate={controls}
          variants={variants}
          exit="closed"
          className="fixed top-0 left-0 h-screen w-4/5 bg-white shadow-lg z-50"
        >
          <button
            onClick={onClose}
            className="flex text-black p-3 items-end justify-end"
          >
            <box-icon type="solid" name="x-circle" size="md"></box-icon>
          </button>
          <motion.nav
            className="flex flex-col items-start h-full p-5 text-black"
            variants={variants}
          >
            {menuItems.map((item, index) =>
              item.subLinks ? (
                <DropdownLink
                  key={index}
                  title={item.title}
                  subLinks={item.subLinks}
                />
              ) : (
                <SideMenuLink key={index} title={item.title} />
              )
            )}
          </motion.nav>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SideMenu;
