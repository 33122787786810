import React from 'react'
import { Helmet } from 'react-helmet-async'
import TopNavigationBar from '../components/TopNavigationBar'
import HeroSection from '../sections/touristVisa/HeroSection'
import Content from '../sections/touristVisa/Content'
import Footer from '../components/Footer'
import FloatingButtons from '../components/FloatingButtons'

export default function TouristVisa() {
  return (
    <div>
       <Helmet>
        <title>Femfly - Tourist Visa</title>
        <meta name="description" content="Femfly is a leading travel agency specialized in providing tourist visa services. Explore our affordable visa solutions for your next trip." />
        <meta name="keywords" content="Femfly, Femfly Travel Agency, Tourist Visa, Visa Services, Travel Agency, Visa Assistance" />
      </Helmet>
      <TopNavigationBar textColor="white" hoverColor="fem-blue"/>
      <HeroSection/>
      <Content/>
      <Footer/>
      <FloatingButtons/>
    </div>
  )
}
