import React from "react";

export default function RightArrowIcon(props) {
  const { onClick, style } = props;

  return (
    <div
      onClick={onClick}
      style={style}
      className="cursor-pointer rounded-full bg-white shadow-lg shadow-gray-400 p-3 w-12 h-12 flex items-center justify-center z-30"
    >
      <box-icon name="right-arrow" type="solid" color="black"></box-icon>
    </div>
  );
}
