import React from "react";

export default function HeroSection() {
  return (
    <div className="relative w-full bg-countries h-[70vh] sm:h-[50vh] bg-center bg-cover bg-no-repeat flex items-center justify-center">
      <div className="bg-black opacity-60 h-full absolute top-0 left-0 right-0"></div>
      <div className="grid text-white text-center z-20">
        <h1 className="text-3xl sm:text-2xl font-bold my-5">Countries</h1>
        <p>Global Gateways: Navigating the World's Opportunities</p>
      </div>
    </div>
  );
}
