import React from "react";
import ProcessCard from "../../components/ProcessCard";

export default function Process() {
  const processCardData = [
    {
      thumbnail: "bg-consultancy",
      title: "Initial Consultation and Document Review",
      description:
        "Our process begins with a thorough consultation to understand your travel needs and review your documents. This step ensures that we tailor our services to your specific requirements.",
    },
    {
      thumbnail: "bg-planning",
      title: "Strategic Planning and Application",
      description:
        "Based on the initial assessment, we devise a strategic plan outlining the most suitable visa options and travel arrangements. Our team assists with the entire application process, ensuring accuracy and compliance with all requirements.",
    },
    {
      thumbnail: "bg-support",
      title: "Continuous Support and Follow-Up",
      description:
        "We believe in supporting our clients beyond the application submission. Our team provides regular updates on the application status and is always available to answer queries and provide assistance during the waiting period.",
    },
    {
      thumbnail: "bg-approval",
      title: "Post-Approval Guidance",
      description:
        "Once the visa is approved, we offer guidance on travel preparations, settling in the new country, and any additional services like job search assistance or local orientation.",
    },
    {
      thumbnail: "bg-relationship",
      title: "Long-Term Relationship",
      description:
        "Our commitment to our clients extends beyond a single journey. We aim to be your lifelong travel partner, assisting with future travels, renewals, and any other travel-related needs.",
    },
  ];
  return (
    <div className="mx-16 sm:mx-4 my-8">
      <h1 className="text-3xl font-bold my-4">Our Process</h1>
      {processCardData.map((data, index) => (
        <div className="sm:my-4">
          <ProcessCard
            key={index}
            title={data.title}
            thumbnail={data.thumbnail}
            description={data.description}
          />
        </div>
      ))}
    </div>
  );
}
