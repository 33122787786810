import React from "react";
import Review from "../../assets/images/homepage/reviews.png"
import Testimonials from "./Testimonials";

export default function Reviews() {
  return (
    <div className="grid grid-cols-5 sm:flex sm:flex-col-reverse px-16 sm:px-4 my-8">
      <div className="col-span-2">
        <h1 className="text-xl font-bold text-fem-blue"> What They Say</h1> 
        <p className="text-4xl my-2 font-bold">What Our Customer Say About Us</p>
        <Testimonials/>
      </div>
      <div className="col-span-3">
        <img src={Review} alt={Review} />
      </div>
    </div>
  );
}
