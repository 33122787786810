import React from "react";

export default function TestimonialCard({message, author,occupation,authorInitial}) {
  return (
    <div className="shadow-lg shadow-gray-400 px-8 py-12 h-[45vh] sm:h-[50vh] rounded-lg relative border">
      <p>
        {message}
      </p>

      <div className="flex gap-2 my-3 absolute bottom-2 left-7">
        <div className="h-12 w-12 bg-orange-400 rounded-full flex items-center justify-center font-bold">{authorInitial}</div>
        <div className="grid">
          <p>{author}</p>
          <p>{occupation}</p>
        </div>
      </div>
    </div>
  );
}
