import React from "react";
import { Helmet } from 'react-helmet-async'
import HeroSection from "../sections/ourServices/HeroSection";
import TopNavigationBar from "../components/TopNavigationBar";
import Content from "../sections/ourServices/Content";
import Footer from "../components/Footer";
import FloatingButtons from "../components/FloatingButtons";

export default function OurServices() {
  return (
    <div>
      <Helmet>
        <title>Femfly - Our Services</title>
        <meta name="description" content="Discover Femfly's comprehensive travel services, including visa assistance, flight reservations, hotel bookings, and expert travel consultation. Explore our range of services to make your travel experience seamless and unforgettable." />
        <meta name="keywords" content="Femfly, Femfly Travel Agency, travel services, visa assistance, flight reservations, hotel bookings, travel consultation, settlement support, legal migration, global mobility, cultural adaptation, travel documentation, visa application, travel agency" />
      </Helmet>
      <TopNavigationBar textColor="black" hoverColor="fem-blue"/>
      <HeroSection />
      <Content/>
      <Footer/>
      <FloatingButtons/>
    </div>
  );
}
