import React from "react";
import Tourist from "../../assets/images/tourist/travel.webp";

export default function Content() {
  const experience = [
    "Scenic Serenity: Enjoy the tranquility of nature's masterpieces, from azure seas to untouched wilderness.",
    "Historical Odyssey: Traverse through time in ancient cities, uncovering the layers of human heritage.",
    "Cityscape Discoveries: Delve into the vibrancy of city life, experiencing the dynamic pulse of diverse metropolises.",
    "Natural Majesty: Witness the grandeur of natural phenomena, inspiring awe and wonder at every turn.",
  ];

  const advantages = [
    {
      title: "Cultural Exploration",
      description:
        "Experience the richness of diverse cultures, historic exploration, and culinary delights.",
    },
    {
      title: "Personal Enrichment",
      description:
        "Travel not only as a tourist but as a learner, enhancing your world view and gaining new perspectives.",
    },
    {
      title: "Relaxation and Rejuvenation",
      description:
        "Our visas offer a peaceful escape to refresh and renew yourself in serene surroundings.",
    },
    {
      title: "Wellness and Mindfulness",
      description:
        "Journey to places that nourish the soul, foster mental well-being, and promote a sense of inner peace.",
    },
    {
      title: "Language and Learning",
      description:
        "Engage with locals, practice new languages, and develop skills that only travel can teach.",
    },
  ];
  return (
    <div className="p-16 sm:p-6">
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-10 my-6">
        <div>
          <h1 className="text-3xl font-bold mb-6"> Femfly Tourist Visa</h1>
          <p>
            Unlock the door to awe-inspiring destinations and create memories
            that will last a lifetime with Femfly's Tourist Visa services.
            Designed for the curious and the adventurous, our visas provide the
            freedom to immerse yourself in the beauty of the world's landscapes,
            from the bustling streets of cosmopolitan cities to the tranquil
            retreats of remote beaches.
          </p>
          <h1 className="text-3xl font-bold my-6">Femfly Experiences:</h1>
          <ul className="list-disc pl-5 space-y-2">
            {experience.map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
        </div>
        <img
          src={Tourist}
          alt={Tourist}
          className="h-[75vh] sm:h-[40vh] rounded-3xl w-full object-cover object-center"
        />
      </div>
      <div className="my-6">
        <h1 className="text-3xl font-bold mb-6">
          Advantages of a Femfly Tourist Visa
        </h1>
        <ul className="list-disc pl-5 space-y-2">
          {advantages.map((advantage, index) => (
            <li key={index}>
              <strong>{advantage.title}:</strong> {advantage.description}
            </li>
          ))}
        </ul>
      </div>
      <p>
        With Femfly, your travel is more than a departure; it's the beginning of
        a story waiting to be written.
      </p>
    </div>
  );
}
