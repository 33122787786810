import React from "react";
import CountryCard from "../../components/CountryCard";
export default function Content() {
    const countries = [
        {
            bgImage: "bg-germany",
            country: "Germany",
            state: "Berlin, Egypt",
        },
        {
            bgImage: "bg-finland",
            country: "Finland ",
            state: "Vesilahti, Finland",
        },
        {
            bgImage: "bg-ireland",
            country: "Ireland",
            state: "County Dublin, Ireland",
        },
        {
            bgImage: "bg-poland",
            country: "Poland",
            state: "Warsaw, Masovian Voivodeship, Poland",
        },
        { bgImage: "bg-greece", country: "Greece", state: "Santorini, Greece" },
        { bgImage: "bg-italy", country: "Italy", state: " Veneto, Italy" },
        { bgImage: "bg-canada", country: "Canada", state: "Toronto, Canada" },
        { bgImage: "bg-england", country: "Uk", state: "England, UK" },
        { bgImage: "bg-usa", country: "USA", state: "New York, USA" },
    ];
    return (
        <main className="grid grid-cols-4 mt-16 sm:mt-20 p-16">
            {countries.map((card, i) => (
                <div key={i} className="p-4 sm:p-2">
                    <CountryCard
                        bgImage={card.bgImage}
                        country={card.country}
                        state={card.state}
                    />
                </div>
            ))}
        </main>
    );
}
