import React from "react";
import HeroSection from "../sections/about/HeroSection";
import TopNavigationBar from "../components/TopNavigationBar";
import { Helmet } from 'react-helmet-async'
import Approach from "../sections/about/Approach";
import Process from "../sections/about/Process";
import Footer from "../components/Footer";
import FloatingButtons from "../components/FloatingButtons";

export default function About() {
  return (
    <div className="grid">
       <Helmet>
        <title>Femfly - About Us</title>
        <meta name="description" content="Learn more about Femfly Travel Agency and our mission to provide exceptional travel experiences. Discover our team, our values, and our commitment to delivering personalized service and comprehensive travel solutions." />
        <meta name="keywords" content="Femfly, Femfly Travel Agency, about us, travel agency, mission, values, travel experiences, personalized service, comprehensive travel solutions" />
      </Helmet>
      <TopNavigationBar textColor="black" hoverColor="fem-blue" />
      <HeroSection />
      <Approach/>
      <Process/>
      <Footer/>
      <FloatingButtons/>
    </div>
  );
}
