import React from "react";
import { Helmet } from 'react-helmet-async'
import HeroSection from "../sections/homepage/HeroSection";
import TopNavigationBar from "../components/TopNavigationBar";
import About from "../sections/homepage/About";
import Blogs from "../sections/homepage/Blogs";
import Reviews from "../sections/homepage/Reviews";
import Footer from "../components/Footer";
import FloatingButtons from "../components/FloatingButtons"; 

export default function HomePage() {
  return (
    <div className="">
      <Helmet>
        <title>Femfly - Homepage</title>
        <meta
          name="description" 
          content="Welcome to Femfly Travel Agency, your gateway to global adventures! Discover our comprehensive travel services, including visa assistance, flight reservations, hotel bookings, and expert travel consultation. Plan your next trip with Femfly and embark on an unforgettable journey!"
        />
        <meta
          name="keywords"
          content="Femfly, Femfly Travel Agency, travel agency, global adventures, visa assistance, flight reservations, hotel bookings, travel consultation, travel services"
        />
      </Helmet>
      <TopNavigationBar textColor="#ffffff" hoverColor="black" />
      <HeroSection />
      <About />
      <Blogs />
      <FloatingButtons/>
      <Reviews />
      <Footer />
    </div>
  );
}
