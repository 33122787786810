import React from "react";
import Students from "../../assets/images/student/graduate.webp";
import Feature from "../../components/Feature";

export default function Content() {
  return (
    <div className="p-16 sm:p-6">
      <h1 className="text-3xl font-bold mb-6">Student Visa</h1>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-10">
        <img
          src={Students}
          alt={Students}
          className="w-4/5 sm:w-full h-[65vh] sm:h-[40vh] object-cover object-center"
        />
        <div>
          <h1 className="text-3xl font-bold">
            Gateway to Global Academia: Your Partner in Educational Ambitions
          </h1>
          <p className="my-6">
            With Femfly Travel Agency, step into a world of educational
            opportunities and cultural exchanges. We facilitate the visa process
            for students aspiring to study in prestigious institutions across
            the globe. Our extensive network includes partnerships with schools,
            colleges, and universities in prime destinations like the USA, UK,
            Australia, Canada, and more, ensuring that your academic goals are
            well within reach.
          </p>
          <p>
            Navigate the path to academic excellence with our dedicated support
            in obtaining your student visa. From the initial application to your
            integration into campus life, Femfly is committed to making every
            phase of your educational pursuit as smooth and enriching as
            possible. For the academically exceptional, we also offer guidance
            on accessing scholarship programs to further your studies.
          </p>
        </div>
      </div>
      <h1 className="text-3xl font-bold my-6 text-center">Our Services</h1>
      <div className="grid grid-cols-4 sm:grid-cols-2 gap-6 my-20">
        <Feature thumbnail="bg-consultancy" title="Career councelling" />
        <Feature
          thumbnail="bg-application"
          title=" School application assistance"
        />
        <Feature
          thumbnail="bg-scholarship"
          title=" Scholarship opportunities"
        />
        <Feature thumbnail="bg-business" title="IELTS training" />
        <Feature thumbnail="bg-visa" title=" Visa application" />
        <Feature thumbnail="bg-health" title=" Health Insurance" />
        <Feature thumbnail="bg-flight" title="Flight Booking" />
        <Feature thumbnail="bg-pickup" title="Airport pickup/drop offs" />
        <Feature thumbnail="bg-support" title="Regular follow ups" />
      </div>
    </div>
  );
}
