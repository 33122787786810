import React from "react";

export default function ContactForm() {
  return (
    <div className="mx-auto w-[90%] my-6 border grid grid-cols-2 sm:grid-cols-1 text-lg">
      <div className="bg-fem-darkblue h-full w-full text-white p-16 sm:p-4 sm:text-sm">
        <h1 className="font-bold my-8">WHY US?</h1>
        <p>
          Femfly Travels and Tours offers comprehensive travel services,
          including visa assistance, flight and hotel bookings, and travel
          consultation. They specialize in simplifying visa processes, providing
          diverse flight options, and securing accommodations ranging from
          budget to luxury. Their expertise also covers personalized travel
          planning and budget advice, ensuring travelers have a seamless
          experience from start to finish.
        </p>
        <div className="my-8">
          <div className="my-4">
            <h1>Email</h1>
            <a href="mailto:starpinstravel@gmail.com">femfly130@gmail.com</a>
          </div>
          <div className="my-4">
            <h1>Phone Number</h1>
            <p>+ 254 720 849 505</p>
            <p>+ 254 114 078 887</p>
          </div>
          <div className="my-4">
            <h1>Address</h1>
            <p>
              3<sup>RD</sup> Floor, Room 305
            </p>
            <p>Harambe Avenue, Cargen House</p>
          </div>
        </div>
      </div>
      <div className="h-full w-full text-black p-16 sm:p-4">
        <h1 className="font-bold my-8">GET IN TOUCH WITH US</h1>
        <div className="my-8">
          <div className="my-4">
            <h1>Name</h1>
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                placeholder="First Name"
              />
              <input
                type="text"
                className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="my-4">
            <h1>Phone Number</h1>
            <input
              type="text"
              className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              placeholder="245-786-555"
            />
          </div>
          <div className="my-4">
            <h1>Email</h1>
            <input
              type="email"
              className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              placeholder="aqw@qwa.com"
            />
          </div>
          <div className="my-4">
            <h1>Message</h1>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              placeholder="Type Message .."
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}
