import React from "react";
import OffersCard from "../../components/OffersCard";

export default function Content() {
  const OffersCardData = [
    {
      title: "Visa Application Guidance",
      description:
        "Visa Application Guidance Comprehensive support in navigating the complexities of various visa applications. Personalized assistance for different visa types such as tourism, business, study, and work visas, ensuring that clients meet the specific requirements and documentation for their intended travel purpose.",
    },
    {
      title: "Settlement Support",
      description:
        "A suite of services designed to ease the transition for clients looking to relocate, including job placement assistance, housing search support, and orientation sessions to acquaint them with their new community and local customs.",
    },
    {
      title: "Legal Migration Paths",
      description:
        "Detailed information on various immigration pathways like Express Entry, Provincial Nominee Programs, and Family Sponsorship. Assistance with understanding eligibility criteria, application processes, and preparing for the necessary steps to achieve permanent residency or citizenship.",
    },
    {
      title: "Global Mobility Insights",
      description:
        "Provision of up-to-date information on countries that offer visa-free travel or visa-on-arrival options, along with expert recommendations on top destinations to visit or settle in, based on current trends and client preferences.",
    },
    {
      title: "Cultural Adaptation Workshops",
      description:
        "Customized sessions to help individuals and families understand and integrate into the culture of their destination. This includes language instruction, understanding local customs, and social etiquette to help new residents navigate social situations confidently.",
    },
    {
      title: "Travel Documentation Management",
      description:
        "Expert management of all necessary travel documents to ensure nothing is overlooked. This service provides peace of mind by organizing and double-checking travel insurance details, health certifications, and ensuring that all emergency information is accurate and accessible.",
    },
  ];
  return (
    <div className="px-16 sm:px-6">
      <div className="text-center">
        <h1 className="text-3xl font-bold capitalize">What We Offer</h1>
        <p className="my-4">
          Femfly is a full-service travel agency dedicated to making your travel
          seamless. Our services include visa assistance for various countries,
          flight reservations, hotel bookings, and expert travel consultation.
          We guide you through intricate visa processes, assist with
          documentation, and ensure smooth planning. Our flight reservation
          system covers both domestic and international routes, offering a range
          of airlines and classes. We provide hotel booking options that cater
          to all budgets and preferences, ensuring comfort wherever you go. Our
          consultation services help with destination selection, itinerary
          planning, and travel budget management. Femfly is committed to
          delivering all-encompassing travel solutions for an unforgettable
          journey.
        </p>
      </div>
      <div className="grid grid-cols-3 sm:grid-cols-1 gap-4">
        {OffersCardData.map((data, index) => (
          <OffersCard
            key={index}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>
    </div>
  );
}
