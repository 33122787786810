import React from "react";

export default function Approach() {
  return (
    <div className="mx-16 sm:mx-6">
      <h1 className="text-3xl font-bold my-4">Our Approach</h1>
      <div className="grid grid-cols-3 sm:grid-cols-1 w-full h-[55vh] sm:h-[150vh] text-white relative">
        <div className="h-[55vh] w-full bg-fem-blue p-8">
          <h1 className="font-bold text-xl my-4">
            Personalized and Comprehensive Support
          </h1>
          <p>
            At Femfly Travel Agency, we understand that each traveler's journey
            is unique. Our approach is centered around providing personalized
            guidance tailored to the specific needs and aspirations of our
            clients. We delve into understanding your travel goals, preferences,
            and circumstances to offer customized solutions.
          </p>
        </div>
        <div className="h-[55vh] w-full bg-fem-darkblue p-8">
          <h1 className="font-bold text-xl my-4">Expertise and Reliability</h1>
          <p>
            Our team comprises seasoned travel and immigration experts who bring
            a wealth of knowledge and experience to the table. We stay abreast
            of the latest travel regulations, visa policies, and global trends
            to offer accurate and reliable information. Trust and transparency
            are the cornerstones of our interactions, and we strive to build
            lasting relationships based on these values.
          </p>
        </div>
        <div className="h-[55vh] sm:h-[40vh] bg-fem-lightblue p-8 text-black">
          <h1 className="font-bold text-xl my-4">Technological Integration</h1>
          <p>
            Leveraging technology, we streamline the visa application process
            and other travel-related procedures. Our digital platforms ensure a
            smooth, efficient, and hassle-free experience for our clients, from
            document submission to application tracking.
          </p>
        </div>
      </div>
    </div>
  );
}
