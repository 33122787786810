import React from "react";
import { Helmet } from 'react-helmet-async'
import TopNavigationBar from "../components/TopNavigationBar";
import HeroSection from "../sections/businessVisa/HeroSection";
import Content from "../sections/businessVisa/Content";
import Footer from "../components/Footer";
import FloatingButtons from "../components/FloatingButtons";

export default function BusinessVisa() {
  return (
    <div>
      <Helmet>
        <title>Femfly - Contact Us</title>
        <meta
          name="description"
          content="Contact Femfly Travel Agency for all your travel inquiries and assistance. Reach out to us for visa services, flight reservations, hotel bookings, and expert travel consultation. Our dedicated team is here to help you plan your next adventure!"
        />
        <meta
          name="keywords"
          content="Femfly, Femfly Travel Agency, contact us, travel inquiries, visa services, flight reservations, hotel bookings, travel consultation"
        />
      </Helmet>
      <TopNavigationBar textColor="white" hoverColor="fem-blue" />
      <HeroSection />
      <Content />
      <Footer />
      <FloatingButtons/>
    </div>
  );
}
