import React from "react";
import Business from "../../assets/images/business/building.webp";

export default function Content() {
  const services = [
    "Customized Business Travel Solutions: Visas tailored to meet the demands of business professionals, ensuring a streamlined travel experience.",
    "Strategic Global Networking: Opportunities to attend premier business events and forge valuable connections for expanding your network.",
    "Market Exploration: Leverage our visas to investigate and enter new international markets, driving the growth and reach of your business.",
    "Professional Prestige: A Femfly business visa enhances your reputation, demonstrating a commitment to global commercial engagement.",
    "Extended Stay Flexibility: Our business visa options provide longer durations than typical tourist visas, allowing for thorough and effective management of business affairs abroad.",
  ];
  return (
    <div className="px-16 sm:p-6 pt-16">
      <div className="grid grid-cols-6 sm:grid-cols-1 sm:place-items-center gap-4 items-center">
        <div className="grid grid-cols-2 gap-4 col-span-2">
          <div className="h-[30vh] rounded-3xl w-full bg-fem-blue p-4 grid justify-items-end items-end">
            <p className="font-bold text-3xl text-white">Business Visa</p>
          </div>
          <div className="h-[30vh] bg-corporate bg-center bg-cover bg-no-repeat rounded-3xl w-full"></div>
          <div className="h-[35vh] col-span-2 bg-handvisa bg-center bg-cover bg-no-repeat rounded-3xl w-full"></div>
        </div>
        <div className="col-span-4 mx-10 sm:mx-4">
          <h1 className="font-bold text-3xl w-4/5 sm:w-full">
            Expanding Your Corporate Horizons with Femfly
          </h1>
          <p className="my-4">
            Embark on a journey of business expansion with Femfly's tailored
            Business Visitor Visa. Designed for the astute professional, our
            visa services are the conduit to your global business aspirations.
            Whether it's for collaborative meetings in the economic hearts of
            the USA, strategy sessions in the innovation hubs of Canada, or
            networking in the dynamic markets of the UK and the Schengen zone,
            Femfly is your partner in navigating the realm of international
            trade and commerce.
          </p>
          <p className="my-4">
            With Femfly, every trip is more than just travel—it's an opportunity
            to advance your professional prowess, to engage with international
            thought leaders, and to unlock the potential of overseas markets.
            Our comprehensive visa support harmonizes the necessities of global
            connectivity with the simplicity of travel, offering more than just
            entry but a foundation for business growth and enduring
            partnerships. Engage with Femfly, where your global business journey
            begins.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:flex sm:flex-col-reverse my-16 sm:my-8 gap-20 max-h-max">
        <div>
          <h1 className="text-3xl font-bold">About</h1>
          <p className="my-6">
            Our services cater to professionals seeking to expand their business
            horizons globally. The Femfly Business Visitor Visa is designed for
            those aiming to attend meetings, participate in training, or explore
            business opportunities within key global markets.
          </p>
          <h1 className="font-bold my-4 text-2xl w-4/5">
            Benefits of Choosing Femfly for Your Business Visa Needs
          </h1>
          <ul className="list-disc pl-5 space-y-2">
            {services.map((service, index) => (
              <li key={index} className="">
                {service}
              </li>
            ))}
          </ul>
        </div>
        <img
          src={Business}
          alt={Business}
          className="w-full h-[80vh] sm:h-[40vh] object-cover object-center rounded-3xl"
        />
      </div>
    </div>
  );
}
