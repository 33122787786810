import React from "react";
import { Helmet } from 'react-helmet-async'
import TopNavigationBar from "../components/TopNavigationBar";
import HeroSection from "../sections/countries/HeroSection";
import Content from "../sections/countries/Content";
import Footer from "../components/Footer";
import FloatingButtons from "../components/FloatingButtons";

export default function Countries() {
  return (
    <div>
      <Helmet>
        <title>Femfly - Countries</title>
        <meta
          name="description"
          content="Explore the countries for which Femfly Travel Agency provides visa services. Discover detailed information about visa requirements, application processes, and travel tips for your desired destinations. Plan your international trip with Femfly and make visa processing hassle-free."
        />
        <meta
          name="keywords"
          content="Femfly, Femfly Travel Agency, countries, visa services, visa requirements, visa application, travel tips, international travel"
        />
      </Helmet>
      <TopNavigationBar textColor="#ffffff" hoverColor="black" />
      <HeroSection />
      <Content />
      <Footer />
      <FloatingButtons/>
    </div>
  );
}

