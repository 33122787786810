import React from "react";
import { Helmet } from 'react-helmet-async'
import HeroSection from "../sections/contactUs/HeroSection";
import ContactForm from "../sections/contactUs/ContactForm";
import Footer from "../components/Footer";
import TopNavigationBar from "../components/TopNavigationBar";
import FloatingButtons from "../components/FloatingButtons";

export default function ContactUs() {
  return (
    <div>
      <Helmet>
        <title>Femfly - Contact Us</title>
        <meta
          name="description"
          content="Contact Femfly Travel Agency for all your travel inquiries and assistance. Reach out to us for visa services, flight reservations, hotel bookings, and expert travel consultation. Our dedicated team is here to help you plan your next adventure!"
        />
        <meta
          name="keywords"
          content="Femfly, Femfly Travel Agency, contact us, travel inquiries, visa services, flight reservations, hotel bookings, travel consultation"
        />
      </Helmet>
      <HeroSection />
      <TopNavigationBar textColor="white" hoverColor="fem-blue"/>
      <ContactForm />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.811002789567!2d36.82635262272244!3d-1.287518295007334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1127e7f99007%3A0x978325a1a2689b58!2sCargen%20House%2C%20Harambee%20Ave%2C%20Nairobi!5e0!3m2!1sen!2ske!4v1708600886235!5m2!1sen!2ske"
        width="600"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="y business Location Femfly Travel Agency"
        className="w-full mt-8"
      ></iframe>
      <Footer />
      <FloatingButtons/>
    </div>
  );
}
