import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import MouseScroll from "../../components/mouse";
import Happy from "../../assets/images/homepage/image1.webp";

export default function HeroSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const stagger = {
    visible: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.3,
      },
    }),
    hidden: { y: 50, opacity: 0 },
  };

  const slideIn = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
    hidden: {
      x: -100,
      opacity: 0,
    },
  };
  const slideOut = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
    hidden: {
      x: 100,
      opacity: 0,
    },
  };

  const slideUp = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        duration:2
      },
    },
    hidden: {
      y: -350, // Adjust as necessary for the desired starting position
      opacity: 0,
    },
  };

  return (
    <div className="relative bg-hero-image sm:bg-none h-screen bg-no-repeat bg-cover bg-center grid grid-cols-2 sm:grid-cols-1">
      <motion.div
        className="bg-transparent pl-16 sm:p-6 py-28 sm:py-0 grid grid-cols-3 justify-center items-end"
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={slideIn}
      >
        <p className="text-white font-bold col-span-2 sm:hidden">
          With Visa by your side, embark on journeys without the worries of
          currency exchange, payment mishaps, or unexpected hurdles. Visa's
          global acceptance ensures that you can explore the world with ease,
          enjoying seamless transactions and peace of mind. Say goodbye to
          travel hassles and embrace the freedom to wander wherever your heart
          desires. Visa is not just a card; it's your passport to limitless
          adventures.
        </p>
      </motion.div>
      <div className="bg-fem-blue sm:bg-fem-darkblue p-28 sm:px-10 sm:py-24 h-screen flex justify-end items-start">
        <div className="grid">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={slideOut}
          >
            <h1 className="text-4xl  sm:text-2xl font-bold">
              Unlock Boundless Adventures with Visa – Your Ticket to Hassle-Free
              Travel
            </h1>
          </motion.div>

          <button className="px-6 py-4 mt-20 sm:w-1/2 sm:mt-6 text-white bg-black rounded-2xl w-1/3 flex items-center gap-4 justify-center">
            <span>Explore</span>
            <box-icon name="right-arrow-alt" color="white"></box-icon>
          </button>
          <div ref={ref} className="flex gap-4 my-8 sm:my-4">
            {[
              "facebook-circle",
              "instagram-alt",
              "tiktok",
              "linkedin-square",
            ].map((icon, index) => (
              <motion.div
                className="bg-white rounded-md p-2 flex items-center justify-center"
                key={icon}
                custom={index}
                initial="hidden"
                animate={controls}
                variants={stagger}
              >
                <box-icon type="logo" name={icon}></box-icon>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={slideUp}
        className="absolute bottom-5 left-0 right-0 flex justify-center w-full"
      >
        <img
          src={Happy}
          alt="Traveler enjoying a Visa-enabled journey"
          className="w-60 my-8 sm:-my-2 sm:w-32"
        />
      </motion.div>
      <MouseScroll />
    </div>
  );
}
