import React from "react";
import { Helmet } from 'react-helmet-async'
import TopNavigationBar from "../components/TopNavigationBar";
import HeroSection from "../sections/studentVisa/HeroSection";
import Content from "../sections/studentVisa/Content";
import Footer from "../components/Footer";
import FloatingButtons from "../components/FloatingButtons";

export default function StudentVisa() {
  return (
    <div>
      <Helmet>
        <title>Femfly - Student Visa</title>
        <meta name="description" content="Femfly offers comprehensive assistance for student visas, helping students achieve their educational goals abroad. Learn about our visa services and start your academic journey today." />
        <meta name="keywords" content="Femfly, Femfly Travel Agency, Student Visa, Visa Services, Study Abroad, Education Visa, Study Visa" />
      </Helmet>
      <TopNavigationBar textColor="white" hoverColor="fem-blue" />
      <HeroSection />
      <Content />
      <Footer/>
      <FloatingButtons/>
    </div>
  );
}
