import React, { useEffect } from "react";
import "./App.css";
import "boxicons";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomePage from "./web/HomePage";
import About from "./web/About";
import OurServices from "./web/OurServices";
import BusinessVisa from "./web/BusinessVisa";
import TouristVisa from "./web/TouristVisa";
import StudentVisa from "./web/StudentVisa";
import Countries from "./web/Countries";
import ContactUs from "./web/ContactUs";

// ScrollToTop component that uses the useLocation hook
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // This component does not render anything
};

function App() {
  return (
    <div className="font-Montserrat overflow-x-hidden">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/ourServices" element={<OurServices />} />
          <Route path="/visa/business" element={<BusinessVisa />} />
          <Route path="/visa/tourist" element={<TouristVisa />} />
          <Route path="/visa/student" element={<StudentVisa />} />
          <Route path="/countries" element={<Countries />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
