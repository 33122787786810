import React, { useState, useEffect } from "react";
import BlogPreview from "../../components/BlogPreview";
import blogsData from "../../data/Blogs.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeftArrowIcon from "../../components/LeftArrowIcon";
import RightArrowIcon from "../../components/RightArrowIcon";

export default function Blogs() {
  function SampleNextArrow(props) {
    const { onClick } = props;
    const [rightPosition, setRightPosition] = useState("-40px");

    useEffect(() => {
      function handleResize() {
        if (window.innerWidth < 600) {
          // Adjust the 600px breakpoint as needed
          setRightPosition("-10px");
        } else {
          setRightPosition("-40px");
        }
      }

      // Set the position on initial render
      handleResize();

      // Add event listener for window resize
      window.addEventListener("resize", handleResize);

      // Clean up the event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <RightArrowIcon
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          right: rightPosition,
          transform: "translateY(-120%)",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    const [leftPosition, setLeftPosition] = useState("-40px");

    useEffect(() => {
      function handleResize() {
        if (window.innerWidth < 600) {
          // You can adjust the 600px breakpoint as needed
          setLeftPosition("-10px");
        } else {
          setLeftPosition("-40px");
        }
      }

      // Set the position on initial render
      handleResize();

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Clean up the event listener
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
      <LeftArrowIcon
        style={{
          display: "block",
          position: "absolute",
          top: "50%",
          left: leftPosition,
          transform: "translateY(-120%)",
        }}
        onClick={onClick}
      />
    );
  }
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="px-16 my-16 sm:px-6">
      <h1 className="text-xl font-bold text-fem-blue  my-3">Latest Blogs</h1>
      <p className="mb-16 text-3xl font-bold">Updated Blog Posts</p>{" "}
      <div className="my-8">
        <Slider {...sliderSettings}>
          {blogsData.Blogs.map((blog, i) => (
            <div key={i} className="p-4 sm:p-2">
              <BlogPreview
                title={blog.title}
                category={blog.category}
                content={blog.content}
                author={blog.author}
                postedDate={blog.postedDate}
                thumbnail={blog.thumbnail}
                alt={blog.alt}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
