import React from "react";

export default function OffersCard({thumbnail,title,description}) {
  return (
    <div className="relative h-[50vh] sm:h-[45vh] rounded-3xl bg-gradient-to-br from-blue-400 to-blue-600 hover:shadow-xl cursor-pointer">
      <div className="absolute bottom-0 left-0 z-30 p-10 grid place-items-end justify-items-start">
        <h2 className="text-white font-bold text-left my-6">{title}</h2>
        <p className="text-white">{description}</p>
      </div>
    </div>
  );
}
